<ng-container *ngIf="formGroup?.get([this.fieldName])" [formGroup]="formGroup">
  <input
    nz-input
    *ngIf="formInputType === FormInputType.TEXT"
    [formControlName]="fieldName"
    [placeholder]="placeholder"
    type="text"
  />
  <input
    nz-input
    *ngIf="formInputType === FormInputType.CURRENCY"
    [formControlName]="fieldName"
    [placeholder]="placeholder"
    type="text"
    inputmode="numeric"
    pattern="^(\d{1,5}|\d{0,5}\.\d{1,2})$"
    avaDigitOnly
    [decimal]="true"
  />
</ng-container>

<!--
  <input
    nz-input
    *ngIf="formInputType === FormInputType.CURRENCY"
    [formControlName]="fieldName"
    [placeholder]="placeholder"
    type="text"
    data-type="currency"
    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
  >
-->
