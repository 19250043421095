import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { StateService } from '../services/state';
import { FormService } from '../services/form';

export interface RadioOption {
  label: string;
  value: string | number;
}

@Component({
  selector: 'ava-generic-radio',
  templateUrl: './radio.html',
  providers: [RxState, RxEffects],
})
export class RadioComponent implements OnInit {
  @Input() stateId: string | undefined;
  @Input() fieldName: string | undefined;
  @Input() fieldPath: string[] = [];
  @Input() options: RadioOption[] = [];
  formGroup: FormGroup | undefined | null;

  constructor(
    private stateService: StateService,
    private formService: FormService,
    private rxEffects: RxEffects,
  ) {}

  validateStatus$(fieldForm: FormControl): Observable<any> {
    return fieldForm.valueChanges.pipe(
      tap((fieldChanges) => {
        /**
         * setValue is required when we have the same edit field viewable in more that one place simultaneously
         */
        // fieldForm.setValue(fieldChanges, { emitEvent: false })
      }),
    );
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.formGroup = this.formService.forms[this.stateService.states[this.stateId].get('block', 'id')]?.get(
        this.fieldPath,
      ) as FormGroup | null;
    }
    if (this.formGroup && this.fieldName && !this.formGroup.get([this.fieldName])) {
      this.formGroup.addControl(this.fieldName, new FormControl(null));
      const fieldForm = this.formGroup.get([this.fieldName]) as FormControl;
      if (fieldForm) {
        this.rxEffects.register(this.validateStatus$(fieldForm));
      }
      if (!fieldForm) {
        console.error(this.fieldName + ' formControl does not exist');
      }
    }
  }
}
